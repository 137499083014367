<template>
  <label
    class="flex flex-row justify-between items-center space-x-2 w-full p-2 pr-4 border rounded bg-white hover:bg-gray-50 cursor-pointer"
    :class="{ 'muted' : isInstagramValidatorsLoaded && !isEnabled }"
    v-if="socialNetwork"
  >          
    <div
      class="flex flex-row justify-start items-start space-x-2"            
    >
      <div class="flex-none">
        <i
          class="h-10 w-10 rounded-full text-white fab text-2xl flex justify-center items-center"
          :class="story ? socialIcons.instagramStories.class : socialIcons.instagram.class"
          :style="story ? socialIcons.instagramStories.style : socialIcons.instagram.style"
        >
        </i>
      </div>
      <div>
        <small class="font-medium text-gray-600 capitalize">
          {{ socialNetwork.source }}{{ story ? ' (Story)' : '' }}
        </small>
        <div>
          {{ socialNetwork.username ? `@${socialNetwork.username}` : socialNetwork.name }}
        </div>

        <div
          v-if="!isSupportedDestinations"
          class="flex items-start space-x-2 bg-yellow-200 border border-yellow-400 mt-1 p-1 rounded-md"
        >
          <i class="fas fa-exclamation-triangle text-yellow-500 mt-1"></i>
          <p
            v-html="$t('page.content.preview.sentence.instagram_requirements-html')"
          />
        </div>

        <div
          v-if="!hasInstagramPublishPermission && isInstagramValidatorsLoaded"
        >
          <new-facebook-permission-button
            :permissions="['instagram_content_publish']"
            @connected="fetchPermissions"
          >
            <i class="fas fa-exclamation-triangle mr-1"></i>
            Autorize a Manycontent a publicar no seu perfil
          </new-facebook-permission-button>
        </div>

        <div
          class="flex items-start space-x-2 bg-yellow-200 border border-yellow-400 mt-1 p-1 rounded-md"
          v-if="!instagramBusiness && isInstagramValidatorsLoaded"
        >
          <i class="fas fa-exclamation-triangle text-yellow-500 mt-2"></i>
          <div>
            {{ $t('page.publish.sentence.instagram_business_message') }}
            <a href="https://suporte.manycontent.com/faq/article/como-publicar-nas-redes-sociais" target="_blank">
              {{ $t('action.see_more') }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!isInstagramValidatorsLoaded"
    >
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    <input
      v-else
      v-model="value"
      :id="story ? 'instagram-story' : 'instagram'"
      :value="story ? 'instagram-story' : 'instagram'"
      name="selectedSocialNetworks"
      type="checkbox"
      class="border-gray-300 rounded cursor-pointer"
      :disabled="!isEnabled"
    />
  </label>
</template>

<script lang="ts">
import { usePost } from '@/api/publications/usePosts'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { computed, defineComponent, onMounted, ref, unref } from 'vue'
import NewFacebookPermissionButton from '../social-media/NewFacebookPermissionButton.vue'

export default defineComponent({
  components: { NewFacebookPermissionButton },

  props: { 
    'modelValue': String,
    story: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'update:modelValue'
  ],
  
  setup (props, { emit }) {
    const source = 'instagram'
    const isLoadedFacebookPermissions = ref<boolean>(false)
    const isLoadedInstagramBusinessVerification = ref<boolean>(false)
    const instagramBusiness = ref<boolean>(false)
    const hasInstagramPublishPermission = ref<boolean>(false)
    
    const {
      fetchFacebookPermissions,
      isInstagramBusiness,
      isLoaded,
      socialIcons,
      availables
    } = useSocialAccounts()

    const {
      supportedDestinations
    } = usePost()

    const value = computed({ 
      get: () => props.modelValue, 
      set: (value) => emit('update:modelValue', value) 
    })

    const socialNetwork = computed(() => {
      return source in unref(availables) && unref(availables)[source] ?
      {
        source,
        ...unref(availables)[source]
      } :
      null
    })

    const isInstagramValidatorsLoaded = computed(() => {
      return isLoadedFacebookPermissions.value && isLoadedInstagramBusinessVerification.value
    })

    const isEnabled = computed(() => {
      return instagramBusiness.value && hasInstagramPublishPermission.value && isSupportedDestinations.value
    })

    onMounted( async () => {
      fetchPermissions()
      checkInstagramIsBusiness()
    })

    const checkInstagramIsBusiness = async () => {
      try {
        instagramBusiness.value = await isInstagramBusiness()
      } catch (error) {
        console.log(error)
      } finally {
        isLoadedInstagramBusinessVerification.value = true
      }
    }

    const fetchPermissions = async () => {
      try {
        const permissions = await fetchFacebookPermissions()
        hasInstagramPublishPermission.value = permissions.includes('instagram_content_publish')
      } catch (error) {
        console.log(error)
      } finally {
        isLoadedFacebookPermissions.value = true
      }
    }

    const isSupportedDestinations = computed(() => {
      return supportedDestinations.value[props.story ? 'instagram-story' : source] || false
    })

    return {
      value,
      socialNetwork,
      isLoaded,
      instagramBusiness,
      hasInstagramPublishPermission,
      socialIcons,
      isEnabled,
      isInstagramValidatorsLoaded,
      isSupportedDestinations
    }
  }
})
</script>

<style scoped>
@layer components {
  .muted {
    @apply cursor-not-allowed filter;
    filter: grayscale(1);
    * {
      @apply cursor-not-allowed;
    }
    a {
      @apply cursor-pointer;
    }
    img {
      filter: grayscale(1);
    }
  }

  .alert a {
    @apply font-semibold;
  }
}
</style>
