<template>
  <label
    class="flex flex-row justify-between items-center space-x-2 w-full p-2 pr-4 border rounded bg-white hover:bg-gray-50 cursor-pointer"
    :class="{ 'muted' : !isEnabled }"
    v-if="socialNetwork"
  >          
    <div
      class="flex flex-row justify-start items-start space-x-2"            
    >
      <div class="flex-none">
        <i
        class="h-10 w-10 rounded-full text-white fab text-2xl flex justify-center items-center"
          :class="socialIcons[socialNetwork.source].class"
          :style="socialIcons[socialNetwork.source].style"
        >
        </i>
      </div>
      <div>
        <small class="font-medium text-gray-600 capitalize">
          {{ socialNetwork.source }}
        </small>
        <div>
          {{ socialNetwork.username ? `@${socialNetwork.username}` : socialNetwork.name }}
        </div>
        <div
          v-if="!isSupportedDestinations"
          class="flex items-start space-x-2 bg-yellow-200 border border-yellow-400 mt-1 p-1 rounded-md"
        >
          <i class="fas fa-exclamation-triangle text-yellow-500 mt-1"></i>
          <p
            v-html="$t('page.content.preview.sentence.facebook_requirements-html')"
          />
        </div>
      </div>
    </div>
    <input
      v-model="value"
      :id="socialNetwork.source"
      :value="socialNetwork.source"
      name="selectedSocialNetworks"
      type="checkbox"
      class="border-gray-300 rounded cursor-pointer"
      :disabled="!isEnabled"
    />
  </label>
</template>

<script lang="ts">
import { computed, defineComponent, unref } from 'vue'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { usePost } from '@/api/publications/usePosts'

export default defineComponent({
  props: { 
    'modelValue': String, 
  },

  emits: [
    'update:modelValue'
  ],
  
  setup (props, { emit }) {
    const source = 'facebook'
    const {
      isLoaded,
      socialIcons,
      availables
    } = useSocialAccounts()

    const {
      supportedDestinations
    } = usePost()

    const value = computed({ 
      get: () => props.modelValue, 
      set: (value) => emit('update:modelValue', value) 
    })

    const socialNetwork = computed(() => {
      return source in unref(availables) && unref(availables)[source] ?
      {
        source,
        ...unref(availables)[source]
      } :
      null
    })

    const isSupportedDestinations = computed(() => {
      return supportedDestinations.value[source] || false
    })

    const isEnabled = computed(() => {
      return isSupportedDestinations.value
    })

    return {
      value,
      socialNetwork,
      isLoaded,
      socialIcons,
      isEnabled,
      isSupportedDestinations
    }
  }
})
</script>

<style scoped>
@layer components {
  .muted {
    @apply cursor-not-allowed filter;
    filter: grayscale(1);
    * {
      @apply cursor-not-allowed;
    }
    a {
      @apply cursor-pointer;
    }
    img {
      filter: grayscale(1);
    }
  }

  .alert a {
    @apply font-semibold;
  }
}
</style>
