<template>
  <base-modal v-model:open="open" hide-footer :size="modalSize">
    <template v-slot:button>
      <button class="btn btn-secondary">
        <i class="far fa-calendar-alt"></i> {{ $t('action.schedule') }}
      </button>
    </template>

    <div class="card-body p-0">
      <div
        class="grid grid-cols-1"
        :class="{ 'lg:grid-cols-2': publishOption === 'schedule' }"
      >
        <div class="px-4 pt-4">
          <div
            class="flex flex-col justify-center items-start"
          >
            <div class="card-title pb-4">
              {{ $t('action.publish') }}
            </div>

            <change-social-accounts
              @changeSocialNetworks="changeSocialNetworks"
              @hasSocialNetworks="setHasSocialNetworks"
            />

            <ul class="my-4 flex flex-col md:flex-row justify-center md:space-x-4 w-full">
              <li class="flex items-center space-x-2">
                <input class="cursor-pointer" type="radio" id="radio-publish" value="publish" v-model="publishOption" />
                <label class="cursor-pointer" for="radio-publish">{{ $t('page.publish.action.publish_now') }}</label>
              </li>
              <li class="flex items-center space-x-2">
                <input class="cursor-pointer" type="radio" id="radio-schedule" value="schedule" v-model="publishOption" />
                <label class="cursor-pointer" for="radio-schedule">{{ $t('page.publish.action.schedule_publication') }}</label>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="flex justify-end w-full p-4 space-x-3 order-3 lg:order-2"
          v-if="hasSocialNetworks"
        >
          <button class="btn" @click.prevent="close">
            {{ $t('action.cancel') }}
          </button>
          <button
            class="btn btn-primary"
            @click.prevent="publish"
            :disabled="!selectedSocialNetworks.length"
            v-if="publishOption === 'publish'"
          >
            <i class="fas fa-share-square"></i> {{ $t('action.publish') }}
          </button>
          <button
            class="btn btn-primary"
            @click.prevent="schedule"
            :disabled="!canSchedule"
            v-if="publishOption === 'schedule'"
          >
            <i class="far fa-calendar-alt"></i> {{ $t('action.schedule') }}
          </button>
        </div>

        <div
          class="lg:row-span-2 bg-gradient-to-tl from-teal-400 to-indigo-700"
          v-if="publishOption === 'schedule'"
        >
          <div
            class="flex flex-col justify-start items-center h-full w-full"
          >
            <div class="flex flex-row w-full">
              <div
                class="tab-button"
                :class="{ 'tab-button-active': selectedTab === 'schedule' }"
                @click="selectedTab = 'schedule'"
              >
                Agendar
              </div>
              <div
                class="tab-button"
                :class="{ 'tab-button-active': selectedTab === 'list' }"
                @click="selectedTab = 'list'"
              >
                Agendamentos
              </div>
            </div>

            <div
              class="p-4 flex flex-col justify-center items-center h-48 lg:h-full w-full"
            >
              <change-date
                @setScheduleTo="setScheduleTo"
                v-if="selectedTab === 'schedule'"
              />
              <schedule-list
                v-else
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { computed, defineComponent, ref, unref } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import { useToggle } from '@/composables/useToggle'
import { isValid, isAfter } from 'date-fns'
import ChangeSocialAccounts from './ChangeSocialAccounts.vue'
import ChangeDate from './ChangeDate.vue';
import ScheduleList from './ScheduleList.vue'

export default defineComponent({
  components: {
    BaseModal,
    ChangeSocialAccounts,
    ChangeDate,
    ScheduleList
  },

  emits: [
    'publish',
    'schedule'
  ],

  setup (props, { emit }) {
    const hasSocialNetworks = ref<boolean>(false)
    const selectedSocialNetworks = ref<string[]>([])
    const publishOption = ref<'publish' | 'schedule'>('publish')
    const selectedTab = ref<'list' | 'schedule'>('schedule')
    const scheduledTo = ref<Date | null>(null)

    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)

    const changeSocialNetworks = (currentSelected: string[]) => {
      console.log(currentSelected)
      selectedSocialNetworks.value = currentSelected
    }

    const setHasSocialNetworks = (hasAccounts: boolean) => {
      hasSocialNetworks.value = hasAccounts
    }

    const setScheduleTo = (date: Date) => {
      scheduledTo.value = date
    }

    const modalSize = computed(() => {
      return publishOption.value === 'schedule' ? 'medium' : 'small'
    })

    const canSchedule = computed(() => {
      const dateToPublish = unref(scheduledTo)
      if (dateToPublish) {
        const isValidSheculeTime = isValid(dateToPublish) && (isAfter(dateToPublish, new Date()))
        return unref(selectedSocialNetworks).length && isValidSheculeTime
      }
      return false
    })

    const publish = () => {
      emit('publish', {
        socialNetworks: unref(selectedSocialNetworks)
      })
      selectedSocialNetworks.value = []
      close()
    }

    const schedule = () => {
      emit('schedule', {
        socialNetworks: unref(selectedSocialNetworks),
        scheduledTo: unref(scheduledTo)
      })
      selectedSocialNetworks.value = []
      scheduledTo.value = null
      close()
    }

    return {
      hasSocialNetworks,
      setHasSocialNetworks,
      changeSocialNetworks,
      selectedSocialNetworks,
      publishOption,
      open,
      close,
      publish,
      schedule,
      setScheduleTo,
      scheduledTo,
      canSchedule,
      modalSize,
      selectedTab
    }
  }
})
</script>

<style scoped>
@layer components {
  .tab-button {
    @apply p-4 w-1/2 text-center text-teal-800 bg-teal-50 uppercase cursor-pointer;
  }

  .tab-button-active {
    @apply text-white bg-transparent;
  }
}
</style>
