<template>
  <form @submit.prevent="handleRequestIdeas" class="mb-12 lg:w-5/6 mx-auto">
    <label for="keyword-select">
      {{ $t('page.headlines_bot.input_keyword_label') }}
    </label>
    <div class="flex items-center justify-items-center">
      <base-input
        type="text"
        :placeholder="$t('page.headlines_bot.input_keyword_placeholder')"
        v-model="keywordInput"
        class-name="rounded-r-none"
      />
      <button
        class="btn btn-secondary rounded-tl-none rounded-bl-none whitespace-nowrap"
        type="submit"
        :disabled="!isValidKeywords || !hasCredit"
      >
        <template v-if="isGenerating">
          <i class="fas fa-spinner fa-spin"></i> {{ $t('sentence.loading') }}
        </template>
        <template v-else>
          {{ $t('page.headlines_bot.resquest_headlines_button_text') }}
        </template>
      </button>
    </div>
    <small v-html="$t('page.headlines_bot.input_keyword_helper-html')" />
  </form>

  <my-ideas />

  <template v-if="!hasCredit">
    <div
      class="card p-4 flex flex-col justify-center items-center mx-auto h-72 w-full lg:w-5/6"
    >
      <div
        class="text-lg text-center leading-relaxed"
        v-html="$t('sentence.no_credit-html')"
      ></div>
      <div class="text-sm text-center mt-12">
        <base-you-need-help-message />
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import MyIdeas from '@/components/posts/idea-generator/MyIdeas.vue'
import { computed, defineComponent, ref, unref } from 'vue'
import { useIdeas } from '@/composables/contentGeneration/useIdeas'
import { useContentPack } from '@/composables/contentPack/useContentPack'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseYouNeedHelpMessage from '@/components/base/BaseYouNeedHelpMessage.vue'

export default defineComponent({
  components: { MyIdeas, BaseInput, BaseYouNeedHelpMessage },
  setup () {
    const keywordInput = ref('')
    const {
      isGenerating,
      requestIdeas
    } = useIdeas()

    const {
      currentHeadlineContentPack
    } = useContentPack()

    const keywords = computed(() => {
      return unref(keywordInput)
        .split(',')
        .map(keyword => keyword.trim().toLowerCase())
        .filter(keyword => !!keyword)
    })

    const isValidKeywords = computed(() => {
      return unref(keywords).length === 3
    })

    const hasCredit = computed(() => {
      return unref(currentHeadlineContentPack) && unref(currentHeadlineContentPack).used < unref(currentHeadlineContentPack).limit
    })

    const handleRequestIdeas = async () => {
      if (unref(isValidKeywords)) {
        await requestIdeas(unref(keywords))
      }
    }

    return {
      hasCredit,
      isGenerating,
      keywordInput,
      isValidKeywords,
      keywords,
      currentHeadlineContentPack,
      handleRequestIdeas
    }
  }
})
</script>

<style scoped>
  button i {
    line-height: 1.1rem;
  }
</style>