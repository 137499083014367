<template>
  <div
    class="flex justify-center items-center w-full"
    v-if="!isLoaded"
  >
    <i class="fas fa-spinner fa-spin"></i>
    <div>{{ $t('sentence.loading') }}</div>
  </div>

  <div
    v-else-if="isLoaded && !availableSocialNetworks.length"
  >
    <div
      class="font-bold text-center"
      v-html="$t('page.analytics.sentence.no_social_account-html')"
    />
  </div>

  <div
    class="w-full"
    v-else
  >
    <div class="mb-3">
      {{ $t('page.publish.sentence.select_where_you_want_to_publish') }}
    </div>
    <div
      class="flex flex-col space-y-2 w-full h-72 overflow-y-auto"
    >
      <instagram-publish-selector v-model="selectedSocialNetworks" />
      <instagram-publish-selector v-model="selectedSocialNetworks" story/>
      <facebook-publish-selector v-model="selectedSocialNetworks" />
      <twitter-publish-selector v-model="selectedSocialNetworks" />
      <linkedin-publish-selector v-model="selectedSocialNetworks" />
      <tiktok-publish-selector v-model="selectedSocialNetworks" v-if="unlockExperimentalFeatures"/>
      <youtube-publish-selector v-model="selectedSocialNetworks" v-if="unlockExperimentalFeatures"/>
    </div>
  </div>
</template>

<script lang="ts">
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { computed, defineComponent, ref, unref, watch, watchEffect } from 'vue'
import InstagramPublishSelector from './InstagramPublishSelector.vue'
import FacebookPublishSelector from './FacebookPublishSelector.vue'
import TwitterPublishSelector from './TwitterPublishSelector.vue'
import LinkedinPublishSelector from './LinkedinPublishSelector.vue'
import TiktokPublishSelector from './TiktokPublishSelector.vue'
import YoutubePublishSelector from './YoutubePublishSelector.vue'
import { currentUser } from '@/composables/user/currentUser'

export default defineComponent({
  components: {
    InstagramPublishSelector,
    FacebookPublishSelector,
    TwitterPublishSelector,
    LinkedinPublishSelector,
    TiktokPublishSelector,
    YoutubePublishSelector,
  },

  emits: [
    'changeSocialNetworks',
    'isLoadedSocialAccounts',
    'hasSocialNetworks'
  ],

  setup (props, { emit }) {
    const selectedSocialNetworks = ref<string[]>([])

    const { user } = currentUser()
    
    const {
      isLoaded,
      availables
    } = useSocialAccounts()

    const availableSocialNetworks = computed(() => {
      return ['instagram', 'facebook', 'linkedin', 'twitter', 'youtube']
        .map(source => {
          return source in unref(availables) && unref(availables)[source] ?
            source :
            null
        })
        .filter(account => account !== null)
    })

    watch(selectedSocialNetworks, () => {
      emit('changeSocialNetworks', selectedSocialNetworks.value)
    })

    watchEffect(() => {
      emit('hasSocialNetworks', availableSocialNetworks.value.length > 0)
    })

    const unlockExperimentalFeatures = computed(() => {
      const userIds = ['skHi69P8radCAUp0adgipYY6tpi1']
      return userIds.includes(unref(user).id)
    })

    return {
      isLoaded,
      selectedSocialNetworks,
      availableSocialNetworks,
      unlockExperimentalFeatures
    }
  }
})
</script>

<style scoped>
@layer components {
  .muted {
    @apply cursor-not-allowed filter;
    filter: grayscale(1);
    * {
      @apply cursor-not-allowed;
    }
    a {
      @apply cursor-pointer;
    }
    img {
      filter: grayscale(1);
    }
  }

  .alert a {
    @apply font-semibold;
  }
}
</style>